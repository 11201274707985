import * as actionType from "../constants/actionTypes";

const demandReducer = (state = { demandData: null }, action) => {
  switch (action.type) {
    case actionType.DEMANDS:
      return { ...state, demands: action.payload };
  
    default:
      return state;
  }
};

export default demandReducer;
