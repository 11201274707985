import * as actionType from "../constants/actionTypes";

const merchandiseReducer = (state = { merchandiseData: null }, action) => {
  switch (action.type) {
    case actionType.COUPONS:
      return { ...state, coupons: action.payload };
    case actionType.COUPONHISTORY:
      return { ...state, couponhistory: action.payload };
    case actionType.NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case actionType.NOTIFICATIONS_COUNT:
      return { ...state, notifications_count: action.payload };
    case actionType.NOTIFICATION_READ:
      return { ...state, notification_read: action.payload };
    case actionType.SLIDER_BANNERS:
      return { ...state, slider_banners: action.payload };
    case actionType.INTROSLIDER:
      return { ...state, intro_slider: action.payload };

    default:
      return state;
  }
};

export default merchandiseReducer;
